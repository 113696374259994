<template>
    <div id="side1">
        <!-- @mouseenter="enter" -->
        <!-- <button @mouseenter="enter">444</button> -->

        <!-- <div class="side1" v-on:mouseenter="enter()"  v-on:mouseleave ="out()" ></div> -->
        <!-- <div class="dangban" v-on:mouseenter="enter1()"  v-on:mouseleave ="out1()"></div> -->
        <div class="box" style="height:100%;" v-clickoutside="handleClose"   v-on:mouseenter="enter1()"  v-on:mouseleave ="out1()" >
            <div class="side1">
                <div class="side"   :class="mouseenter==true?'change':'change1'">
                    <div class="main box box_column" >
                        <div class="div1">
                            <p class="fs18 blod  btnBox" >场景分类</p>
                            <ul class="ul box box_column ">
                                <li class="">
                                    <span :class="{'active':line1==1}"  class="white hand" style="margin-right:62px" @click="go(2,1)">三维场景</span>
                                    <span :class="{'active':line1==2}" class="white hand"  @click="go(3,2)">地图场景</span>
                                </li>
                            </ul>
                        </div>
                        <!-- 颜色区分 -->
                        <div class="div1">
                            <p class="fs18 blod btnBox" >环境分类</p>
                            <div>
                                <span v-for= "(item, index) in colorArr" :key='index' :class="{'active':line2==index}" style="margin-right:30px" class="white hand sp textC"  @click="goColor(item.id, index)">{{item.name}}</span>
                            </div>
                        </div>
                        <!-- 风格区分 -->
                         <div class="div1">
                            <p class="fs18 blod btnBox">风格分类</p>
                            <div>
                                <span v-for= "(item, index) in styleArr" :key='index'  :class="{'active':line3==index}"  style="margin-right:30px" class="white hand sp1 textC" @click="goStyle(item.id,index)">{{item.name}}</span>
                            </div>
                        </div>
                        <!-- 空间区分 -->
                         <div class="div1">
                            <p class="fs18 blod btnBox" >空间分类</p>
                            <div>
                                <span v-for= "(item, index) in spaceArr" :key='index'  :class="{'active':line4==index}" style="margin-right:30px" class="white hand sp1 textC" @click="spaceSelecet(item.id,index)">{{item.name}}</span>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
            <!-- 场景选择 -->
            <div class="box"  v-if="show == true"  >
                <div class="boxshow  box"  v-on:mouseenter="enter2()" :class="mouseenter1==true?'change2':'change3'">
                    <div class="main box box_column" v-if='show1 ==true'>
                        <div class="div1">
                            <p class="fs18 blod  btnBox" >场景分类</p>
                            <ul class="ul box box_column ">
                                <li class="">
                                    <span :class="{'active':line1==1}"  class="white hand" style="margin-right:62px" @click="go(2,1)">三维场景</span>
                                    <span :class="{'active':line1==2}" class="white hand"  @click="go(3,2)">地图场景</span>
                                </li>
                            </ul>
                        </div>
                        <!-- 颜色区分 -->
                        <div class="div1">
                            <p class="fs18 blod btnBox" >环境分类</p>
                            <div>
                                <span v-for= "(item, index) in colorArr" :key='index' :class="{'active':line2==index}" style="margin-right:30px" class="white hand sp textC"  @click="goColor(item.id, index)">{{item.name}}</span>
                            </div>
                        </div>
                        <!-- 风格区分 -->
                         <div class="div1">
                            <p class="fs18 blod btnBox">风格分类</p>
                            <div>
                                <span v-for= "(item, index) in styleArr" :key='index'  :class="{'active':line3==index}"  style="margin-right:30px" class="white hand sp1 textC" @click="goStyle(item.id,index)">{{item.name}}</span>
                            </div>
                        </div>
                        <!-- 空间区分 -->
                         <div class="div1">
                            <p class="fs18 blod btnBox" >空间分类</p>
                            <div>
                                <span v-for= "(item, index) in spaceArr" :key='index'  :class="{'active':line4==index}" style="margin-right:30px" class="white hand sp1 textC" @click="spaceSelecet(item.id,index)">{{item.name}}</span>
                            </div>
                        </div>
                    </div>
                    <div v-else class="main ">
                        <p class="btnBox blod">视频展示</p>
                        <div>
                            <p class="sp2" v-for= "(item, index) in videoArr" :key='index'  >
                                <span   style="margin-right:30px" class="white hand" :class="{'active':line5==index}"  @click="vidwoSelecet(item.id,index)">{{item.name}}</span>
                            </p>
                            
                        </div>
                    </div>
                </div>
            </div>



        </div>
                            
    </div>
</template>

<script>
const clickoutside = {
    // 初始化指令
    bind(el, binding, vnode) {
        function documentHandler(e) {
            // 这里判断点击的元素是否是本身，是本身，则返回
            if (el.contains(e.target)) {
                return false;
            }
            // 判断指令中是否绑定了函数
            if (binding.expression) {
                // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
                binding.value(e);
            }
        }
        // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
        el.__vueClickOutside__ = documentHandler;
        document.addEventListener('click', documentHandler);
    },
    unbind(el, binding) {
        // 解除事件监听
        document.removeEventListener('click', el.__vueClickOutside__);
        delete el.__vueClickOutside__;
    },
};


// var timer;
var timer1;
var timer2;
    export default {
        data() {
            return {
                colorArr:[
                    {'name':'深色','id':'1'}, {'name':'浅色','id':'2'}, {'name':'绿色','id':'3'}, {'name':'蓝色','id':'4'},
                    {'name':'黄绿','id':'5'}, {'name':'蓝橙','id':'6'}, {'name':'棕色','id':'7'}, {'name':'灰色','id':'8'},
                    {'name':'单色','id':'9'}, {'name':'黑色','id':'10'}
                ],
                styleArr:[
                    {'name':'青绿山水','id':'1'}, {'name':'中国风','id':'2'}, {'name':'写实','id':'3'}, 
                    {'name':'low-poly','id':'4'},{'name':'科技','id':'5'}, {'name':'扁平','id':'6'}, 
                    {'name':'建筑绘画','id':'7'}, {'name':'日式写意','id':'8'},{'name':'太空','id':'9'}
                ],
                spaceArr:[
                    {'name':'城市建筑','id':'1'}, {'name':'2.5D模型','id':'2'}, {'name':'2D/3D混合','id':'3'}, 
                    {'name':'3D模型','id':'4'},{'name':'2D模型','id':'5'}, {'name':'地形图','id':'6'}, 
                    {'name':'卫星图','id':'7'}, {'name':'着色','id':'8'}
                ],
                videoArr:[
                    {'name':'湖杭铁路智慧工地','id':'https://cloudansys.biggersun.com/DemoApp_01.mp4'},
                    {'name':'国家会议中心卸载施工监测平台','id':'https://cloudansys.biggersun.com/DemoApp_02.mp4'},
                    {'name':'都四线城市轨道智能管理系统','id':'https://cloudansys.biggersun.com/DemoApp_03.mp4'},
                    {'name':'中国国际丝路中心大厦顶升平台','id':'https://cloudansys.biggersun.com/DemoApp_04.mp4'},
                    {'name':'智慧管网实验方案','id':'https://cloudansys.biggersun.com/DemoApp_05.mp4'}
                ],

                home: true,
                show: false,
                menu: false,
                all: false,
                download:false,
                video: false,

                mouseenter: false,
                mouseenter1: true,

                show1: false,

                line1:'100',
                line2:'100',
                line3:'100',
                line4:'100',
                line5:'100',
                
            }
        },
         directives: {clickoutside},
        watch:{
            show(n, o){
                if(n == true){
                     setTimeout(() => {
                        this.flag = false;
                    }, 5000);
                }
            }

        },
        created () {

        },
        mounted () {
                timer1 = setTimeout(() => {
                        console.log('这是开始的定时器')
                        this.mouseenter = true;    //关闭
                }, 3000);
        },
        methods: {
            // 导航
            goClist(name){
                  setTimeout(timer1);
                  if(name=='home'){
                     console.log('点击菜单了')
                     this.home = true;
                     this.menu = false;
                     this.all = false;
                     this.download = false;
                     this.video = false;
                     this.show = false;
                     this.noActive();   //情况二级菜单选中
                     this.$router.push({
                          path:'/',
                     })
                 }

                //分类
                 if(name=='menu'){ 
                    //  console.log('点击菜单了')
                    clearTimeout(timer1)

                     this.mouseenter = false;  //显示弹框动画
                     this.home = false;
                     this.menu = true;
                     this.all = false;
                     this.download = false; 
                     this.video = false;

                     this.show = true;       //显示二级弹框
                     this.show1 = true;
                     this.mouseenter1 = false;  //显示弹框动画

                    console.log('mouseenter', this.mouseenter);
                 }
                 //缩略图
                 if(name=='all'){   
                     this.noActive();   //情况二级菜单选中
                     this.home = false;
                     this.menu = false;
                     this.all = true;
                     this.mouseenter1 = true;
                     this.download = false;
                     this.video = false;
                     this.$router.push({
                          path:'/picture',
                     })
                 }
                if( name=='download'){
                    this.noActive();   //情况二级菜单选中
                    this.home = false;
                    this.menu = false;
                    this.all = false;
                    this.download = true;
                    this.mouseenter1 = true;
                    this.home = false;
                    this.video = false;
                 }
                 if( name=='video'){
                    this.home = false;
                    this.menu = false;
                    this.all = false;
                    this.download = false;
                    this.home = false;

                    this.video = true;
                    this.show = true;       //显示二级弹框
                    this.mouseenter1 = false;  //显示弹框动画
                    this.show1 = false;    //视频内容
                 }

                 if( name == 'download'){
                    //  console.log('下载')
                    var url = 'https://cloudansys.biggersun.com/cloudansys_solution.pdf'; 
                    let link = document.createElement('a')
                    link.style.display = 'none'
                    link.href = url
                    // link.target = '_black';
                    // link.setAttribute('download', name)   // 文件名
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)    // 下载完成移除元素

                //    var elemIF = document.createElement("iframe");
                //     elemIF.src = url;
                //     elemIF.style.display = "none";
                //     document.body.appendChild(elemIF);

                //     // a 
                //     var a = document.createElement('a');
                //     a.href = url;
                //     a.click();


         
                     
                 }
            },


            //跳转到3d 或者地图
            go(type,index){
                if(index ==1){
                    this.line1 = 1;
                }
                if(index ==2){
                    this.line1 = 2;
                }
                this.line2 = 100;
                this.line3 = 100;
                this.line4 = 100;
                this.line5 = 100;

                var name;
                this.menu = false;
                this.mouseenter1= true;
                this.mouseenter = true;
                // this.out1();
                this.$router.push({
                    path:'/model',
                    query:{
                        typeName: type
                    }
                })
            },
    
 
            //选择深浅色
            goColor(val, index){
                this.line2 = index;
                this.line1 = 100;
                this.line3 = 100;
                this.line4 = 100;
                this.line5 = 100;

                this.menu = false;
                this.mouseenter1= true;   // 板子
                this.mouseenter = true;   // 导航没有了
                // this.out1();
                this.$router.push({
                    path:'/model',
                    query:{
                        color: val,   //颜色值
                        typeName: '1'    //全部内容
                    }
                })

            },

            //选择风格
            goStyle(val,index){
                this.line3 = 0;
                console.log(val,index);
                this.line2 = 100;
                this.line1 = 100;
                // this.line3 = index;
                this.line4 = 100;
                this.line5 = 100;

                this.menu = false;
                this.mouseenter1= true;
                this.mouseenter = true;
                // this.out1();
                this.$router.push({
                    path:'/model',
                    query:{
                        style: val,
                         typeName: '1'    //全部内容
                    }
                })
            },

            //选择质感
            spaceSelecet(val,index){
                this.line2 = 100;
                this.line1 = 100;
                this.line3 = 100;
                this.line4 = index;
                this.line5 = 100;

                this.menu = false;
                this.mouseenter1= true;
                this.mouseenter = true;
                // this.out1();
                this.$router.push({
                    path:'/model',
                    query:{
                        space: val,
                        typeName: '1'    //全部内容
                    }
                })
            },

            // 视频选择
            vidwoSelecet(id, index){
                this.line2 = 100;
                this.line1 = 100;
                this.line3 = 100;
                this.line4 = 100;
                this.line5 = index;

                this.video = false
                this.mouseenter1= true;
                this.mouseenter = true;

                this.$router.push({
                    path:'/video',
                    query:{
                        videoId: id,
                    }
                })

            },

            //情况二级菜单选中
            noActive(){
                this.line2 = 100;
                this.line1 = 100;
                this.line3 = 100;
                this.line4 = 100;
                this.line5 = 100;
            },


            handleClose(){
                console.log('点击空白处');
                console.log('line5',this.line5)
                if(this.line5 == 100){
                     this.video = false;   //清空视频选中样式
                }
                clearTimeout(timer1);
                this.menu = false;
                this.mouseenter1 = true;
                timer1 = setTimeout(() => {   //重新打开定时器 隐藏右侧滚动条
                    console.log('消失')
                    this.mouseenter = true;      //消失
                }, 5000);
            
                
            },


            //既然怒
            enter1(){
                this.mouseenter = false;        //显示
                console.log(timer1)
                clearTimeout(timer1)
                this.mouseenter = false;
                console.log('进进进', this.mouseenter)
                
            },

            out1(){
                console.log('出出出', this.mouseenter)
                if(this.mouseenter1 != false){
                    timer1 = setTimeout(() => {
                         this.mouseenter = true;  
                    }, 1000);
                }
                // console.log(timer1)
            },
            btn1(){
                this.show = true;
                 this.mouseenter1 = false;
            },
            enter2(){
                clearTimeout(timer1);
            },

            






        },
    }
</script>

<style lang="scss" scoped>
    #side1{
        // width: 514px;
        height: 100%;
        // float: left;
        position: absolute;
        z-index: 89;
        // background-color: rgba($color: red, $alpha: 0.5);

    }
    .side{
        width:414px;
        height: 100%;
        // background-color: #234763;
        background: rgba($color: #234763, $alpha: 0.7);
        padding-top: 61px;
        padding-left: 45px;
    }
    .side1{
        position: absolute;
        left: 0;
        width: 108px;
        height: 100%;
        z-index: 999;
        // border: 1px solid red;
    }

    .demo1{
        color: white;
    }




    // 进入   离开结束
    .v-enter,
    .v-leave-to{
        opacity: 0;
        transform: translateX(-110px);
    }

    //  .v-enter-to,
    // .v-leave{
    //     opacity: 0;
    //     transform: translateX(-90px);
    // }

    // 入场的时间段   出场的时间段
    .v-enter-active,
    .v-leave-active{
        transition: all 0.8s ease;   //
    }


    //弹出二级菜单
    .boxshow{
        width: 514px;
        // width: 30%;
        height: 100%;
        // margin-left: 108px;
        // padding-top: 136px;
        // padding-left: 147px;
        background-color: rgba($color: #112433, $alpha: 0.95);
       
        text-align: left;
        

    }
    // .boxshow1{
    //     position: absolute;
    //     left: 0;
    //     width: 414px;
    //     height: 100%;
    //     text-align: left;
    //     z-index: 888;
    //     border: 1px solid yellow;
    // }


    // 进入   离开结束
    .move-enter,
    .move-leave-to{
        opacity: 0;
        transform: translateX(-90px);
    }

    //  .v-enter-to,
    // .v-leave{
    //     opacity: 0;
    //     transform: translateX(-90px);
    // }

    // 入场的时间段   出场的时间段
    .move-enter-active,
    .move-leave-active{
        transition: all 0.8s ease;   //
    }

    .btnBox{
        color: #40434A;
        width: 104px;
        height: 34px;
        background-color: #ffffff;
        border-radius: 5px;
        display: inline-block;
        text-align: center;
        line-height: 34px;
        margin-bottom: 8%;
        
    }


    .change{
        animation: myMou 0.5s;
        animation-fill-mode: forwards;
    }
    .change1{
        animation: myMou1 0.5s;
        animation-fill-mode: forwards;
    }
    @keyframes myMou {
        0% {
            position: absolute;
            left: 0;
        }
        100% {
            position: absolute;
            left: -410px;
        }
    }
    @keyframes myMou1 {
        0% { 
            position: absolute;
            left: -410px;
        }
        100% {
             position: absolute;
            left: 0;
        }
    }


    // 
    .change2{
        animation: myMou2 0.5s;
        animation-fill-mode: forwards;
    }
    .change3{
        animation: myMou3 0.5s;
        animation-fill-mode: forwards;
    }
    @keyframes myMou2 {
        0% {
            position: absolute;
            left: 0;
        }
        100% {
            position: absolute;
            left: -520px;
        }
    }
    @keyframes myMou3 {
        0% { 
            position: absolute;
            left: -520px;
        }
        100% {
            position: absolute;
            left: 0;
        }
    }
    //导航菜单
    .ul1{
       text-align: left;
    }
    .ul1 li{
           margin-bottom: 58px;
    }
    .ul1 img{
        width:30px;
    }



    .main{
        // border: 1px solid red;
        // width: 300px;
        text-align: left;
        height: 60%;
        position: absolute;
        // top: 10%;
        margin-top: 150px;
        // left: 30%;
    }
    .div1{
        
        // height: 45%;
        // background-color: rgba($color: red, $alpha: 0.6);
    }
    .sp{
        display: inline-block;
        width: 50px;
        margin-bottom: 2%;
    }
    .sp1{
        display: inline-block;
        width: 85px;
         margin-bottom: 2%;
    }

    .sp2{
        // display: inline-block;
        // width: 140px;
         margin-bottom: 10%;
         display: block;
    }
  

    .active{
        background-color: #295C84;
        border-radius: 3px;
        padding: 3px;
    }
    .dangban{
        position: absolute;
        left: 0;
        height: 90%;
        width: 120px;
        border: 1px solid red;
    }



</style>