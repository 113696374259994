<template>
    <div class="model">
        <!-- 窗口 -->
        <!-- <div class="windowBox">
            <p>三维场景</p>
            <div class="box">
                <div>环境</div>
                <div>
                    <span>深色</span>
                    <span>浅色</span>
                </div>
            </div>
        </div> -->
        <!-- <side></side> -->


        <!-- :autoplay="false"  -->
          <el-carousel :interval="0"  arrow="always" ref='car'  :initial-index='pageIndex'>
            <el-carousel-item v-for="(item, index) in list" :key='index'>
                <img :src="item.src" style="height:100%;width:100%;" alt="" >
            </el-carousel-item>
        </el-carousel>

        <!-- 右侧控件 -->
        <div class="right">
            <div class="div1 box around center hand" @click="next">
               <span class="right1"></span>
            </div>
            <!-- <div class="div3 box center around">
                <span class="fs24 white">{{number}}</span>
                <span class="fs24 white">/</span>
                <span class="fs14 white">{{lenght}}</span>
            </div> -->
        </div>
        <div class="left">
            <div class="div2 box around center hand" @click="up">
                <span class="right2"></span>
            </div>

        </div>
        <!-- 下侧显示 -->
        <div class="select" v-on:mouseenter="enter()"  v-on:mouseleave ="out()"></div>
        <div  class="bottom" :class="btShow==true? 'change1' : 'change' ">
            <div class="box  flexEnd center">
                <div class="white box flexStart center box1" style="height:160px; width:55%">
                    <div>
                        <span class="fs30 white" >{{number}}</span>
                        <span class="fs30 white">/</span>
                        <span class="fs24 mR20 white" >{{lenght}}</span>
                        <span class="fs26">{{ type |  typeSelect }}</span>
                    </div>
                </div>
                <div class="white box  justify  center" style="height:160px; width:45%">
                    <div style="margin-right:0px" class="div">
                            <span class="fs20">风格：</span>
                             <span class="fs20 mr2" v-for = '(item,index) in style' :key = 'index'>{{item | styleSelect }}</span>
                    </div>
                    <div style="margin-right:0px" class="div">
                        <span class="fs20">环境：</span>
                        <span class="fs20 mr2" v-for = '(item1,index1) in color' :key = 'index1'>{{ item1 | colorSelect }}</span>
                    </div>
                    <div class="box center div11">
                        <span class="fs20" >空间：</span>
                        <span class="fs20 mr2" v-for = '(item2, index2) in space' :key = 'index2'>{{ item2 | spaceSelect }}</span>
                        <div class="divBox">
                            <span v-if="switchData == 1" class="hand switch hand" @click="change('add')">
                                <span class="switch1"></span>
                            </span>
                            <span v-if="switchData == 2" class="hand switch hand" @click="change('reduce')">
                                <span class="switch2"></span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 触摸板 select -->
        <!-- <div class="select" v-on:mouseenter="enter()"  v-on:mouseleave ="out()"></div> -->
        <!-- <div class="box" style="height:100%">
            <img :src="src" alt="" class="img">
        </div> -->
    </div>
</template>


<script>

    var timer;

    import arrData from '../assets/js/arrData.js'
    import mapData from '../assets/js/mapData.js'

    import  side from '../components/side1';

    export default {
         components: {
            side,
        },
        data() {
            return {
                flag: true,
                src: '',
                arr:[],
                number:'',  //编号
                name:'三维场景',   //三维场景  地图
                type:'',  // 显示三维场景， 地图场景
                style:'',  //风格
                color:'',   //浅色  深色
                space:'',   //空间
                lenght:'',  //条数
                flag:0,
                switchData: '',
                noSwitch: false,
                arrData: arrData,
                mapData: mapData,
                list:[],
                typeName:'',
                pageIndex: 0,   //切记一定要是数字类型  如果是字符串，播放第一张时会有显示问题

                newList:[],
                
                btShow: true,

                // list1:[
                //     {'src': require('../assets/img/3d/01.jpg')},
                //     {'src': require('../assets/img/3d/02.jpg')},
                //     {'src': require('../assets/img/3d/03.jpg')},
                // ]
            }
        },
        created () {
            // this.arrData= [];
            // this.arrData = arrData1;
            // console.log(1111,this.arrData)
            // for (let item of this.arrData) {
            //      item.src = require('../assets/img/3d/'+item.src)     //01.jpg
            // }
        },
        // computed: {
        //     listenshowpage1() {
        //         return this.$store.state.stateShow;
        //     }

        // },

        // watch: {
            
        //      listenshowpage1: function(a, b) {
        //          var that = this;
        //         if(a){
        //             that.btShow = false;
        //         }
        //     }
        // },

        mounted () {
            
            timer = setTimeout(() => {
                this.btShow = true;
            }, 2000);

            // console.log('this.$route.query.typeName',this.$route.query.typeName)
            if( this.$route.query.typeName != undefined){
                this.typeName = this.$route.query.typeName;
                if(this.typeName == 1){
                    this.list = this.arrData.concat(this.mapData);
                }
                if(this.typeName == 2){
                    this.list = this.arrData;
                    this.type = '1';
                    console.log('三维');
                }
                if(this.typeName == 3){
                    this.list = this.mapData;
                    this.type = '2';
                    console.log('地图');
                }
            }
            console.log('typeName',this.typeName)
            // this.name = this.$route.query.name;

            //颜色深浅
            if( this.$route.query.color != undefined){
                this.color = this.$route.query.color;
                this.noSwitch =  true;
                this.colorData(this.color);
            }

            //风格样式
            if( this.$route.query.style != undefined){
                this.style = this.$route.query.style;
                this.noSwitch =  true;
                 this.styleData(this.style);
            }

            //空间
            if( this.$route.query.space != undefined){
                this.space = this.$route.query.space;
                this.noSwitch =  true;
                this.spaceData(this.space);
            }


            //传过id
            if( this.$route.query.id != undefined){
                this.showImage(this.$route.query.id);   //查找对应图片
                this.lenght = (this.list.length).toString().padStart(2, '0');
                this.pageIndex = (this.$route.query.id)-1;
                console.log(' this.pageIndex',  this.pageIndex)
                return;
            }

            console.log('list值', this.list)
            var imgData = this.list[0].src;
            this.$store.commit('changeSrc',imgData);


            this.number = (this.flag + 1).toString().padStart(2, '0');
            this.style = this.list[0].style;
            this.color = this.list[0].color;
            this.space = this.list[0].space;
            this.lenght = (this.list.length).toString().padStart(2, '0');
            this.src =  this.list[0].src;
            this.type = this.list[0].type;    // 三维/地图

            if(this.noSwitch == true) {
                this.switchData = 0
            }else{
                this.switchData = this.list[0].switch
            }


        },
        methods: {

            //判断颜色样式
            colorData(val){
                // if(this.color == 1){
                this.list.forEach((item =>{
                    if(item.color.length != 0 ){
                        item.color.some((arr)=>{   //[1,2,3,4,]
                            if(arr == val){
                                this.newList.push(item)
                            }
                        })

                    }
                }))
                this.list = this.newList;  
            },

            //判断类型
            styleData(val){
                this.list.forEach((item =>{
                    if(item.style.length != 0 ){
                        item.style.some((arr)=>{    //[1,2,3,4,]
                            if(arr == val){
                                this.newList.push(item)
                            }
                        })

                    }
                }))
                this.list = this.newList;  

            },

            //判断空间
            spaceData(val){
                this.list.forEach((item =>{
                    if(item.space.length != 0 ){
                        item.space.some((arr)=>{    //[1,2,3,4,]
                            if(arr == val){
                                this.newList.push(item)
                            }
                        })

                    }
                }))
                this.list = this.newList;  
            },




            showImage(id){
                this.flag = id - 1;
                this.number =  (this.flag + 1).toString().padStart(2, '0');
                this.style = this.list[this.flag].style;
                this.color = this.list[this.flag].color;
                this.space = this.list[this.flag].space;
                this.src = this.list[this.flag].src;
                this.switchData = this.list[this.flag].switch;
                // if(this.noSwitch == true){
                //     this.switchData = 0
                // }else{
                //     this.switchData = this.arrData[this.flag].switch;
                // }
            },

            //切换颜色模式
            change(val){
                if(val == 'add'){
                        this.next();
                }
                if(val == 'reduce'){
                    this.up();
                }
            },



            next() {

                clearTimeout(timer);    //关闭定时器 
                this.btShow = true;
                this.out();
                //  console.log(this.lenght)
                if(this.flag + 1 == this.lenght){
                    console.log('到头了')
                  return;
                }
                this.$refs.car.next();
                console.log('list next', this.list)
                this.flag = this.flag + 1;

                var imgData = this.list[this.flag].src;
                this.$store.commit('changeSrc',imgData);

                this.number =  (this.flag + 1).toString().padStart(2, '0');
                this.style = this.list[this.flag].style;
                this.color = this.list[this.flag].color;
                this.space = this.list[this.flag].space;
                this.switchData = this.list[this.flag].switch;
                this.src = this.list[this.flag].src;
                this.type = this.list[this.flag].type;
                if(this.noSwitch == true){
                    this.switchData = 0
                }else{
                    this.switchData = this.list[this.flag].switch;
                }
                
            },
            up(){
                clearTimeout(timer);    //关闭定时器 
                this.btShow = true;
                this.out();      // 再打开定时器


                if(this.flag == 0){
                    // console.log('到头了')
                  return;
                }
                this.$refs.car.prev();

                this.flag = this.flag - 1;

                 var imgData = this.list[this.flag].src;
                this.$store.commit('changeSrc',imgData);

                this.number =   (this.flag + 1).toString().padStart(2, '0');
                this.style = this.list[this.flag].style;
                this.color = this.list[this.flag].color;
                this.space = this.list[this.flag].space;
                this.src = this.list[this.flag].src;
                this.type = this.list[this.flag].type;
                  if(this.noSwitch == true){
                    this.switchData = 0
                }else{
                    this.switchData = this.list[this.flag].switch;
                }
            },
            //鼠标进入
            enter(){
                console.log('触摸关闭定时器')
                clearTimeout(timer);
                this.btShow = true
            },
            out(){
                timer = setTimeout(() => {
                    console.log(999)
                    this.btShow = false;
                }, 3000);
            },
        },
    }
</script>

<style lang="scss" scoped>
    .model{
        width: 100%;
        height: 100%;
        overflow-x: auto;
    }
    .img{
        width: 100%;
        height: 100%;
    }
    .right{
        position: absolute;
        right: 0;
        top: 40%;
        margin-right: 20px;
        z-index: 3;
        // background-color: rgba(35,71,99, 1);
    }
    .div1{
        width: 58px;
        height: 147px;
        background-color: rgba(35,71,99, 0.6);
    }
    .right1{
        display: inline-block;
        width:11px;
        height:23px;
        background: url('../assets/images/iconRight1.png') no-repeat;
        background-size:100% 100%;
        align-items: middle;
    }
    .div1:hover .right1{
        background: url('../assets/images/iconRight2.png') no-repeat;
    }
    .div2{
        width: 58px;
        height: 147px;
        background-color: rgba(35,71,99, 0.6);
    }

    .left{
        position: absolute;
        top: 40%;
        margin-left: 20px;
        z-index: 3;
    }

    .right2{
        display: inline-block;
        width:11px;
        height:23px;
        background: url('../assets/images/iconLeft1.png') no-repeat;
        background-size:100% 100%;
        align-items: middle;
    }
    .div2:hover .right2{
        background: url('../assets/images/iconLeft2.png') no-repeat;
    }


    .div3{
        width: 58px;
        height: 38px;
        background-color: rgba(29,69,99,0.32);
    }

    .bottom{
        position: absolute;
        z-index: 2;
        bottom: 0;
        width: 100%;
        height: 160px;
        padding: 0 40px;
        padding-right: 0;
        background-color: rgba($color: #173145, $alpha: 0.57);

    }

     //触摸动画 
       // 进入   离开结束
    .v-enter,
    .v-leave-to{
        opacity: 0;
        transform: translateY(-160px);
    }

    // 入场的时间段   出场的时间段
    .v-enter-active,
    .v-leave-active{
        transition: all 0.8s ease;   //
    }

    .switch1{
        display: inline-block;
        width:50px;
        height:50px;
        background: url('../assets/images/btn.png') no-repeat;
        background-size:100% 100%;
    }
    .switch2{
        display: inline-block;
        width:50px;
        height:50px;
        background: url('../assets/images/btn.png') no-repeat;
        background-size:100% 100%;
    }

    .switch1:hover, .switch2:hover{
         display: inline-block;
        width:50px;
        height:50px;
         background: url('../assets/images/btn1.png') no-repeat;
         background-size:100% 100%;
    }
    
    .change{
        animation: myMou 0.5s;
        animation-fill-mode: forwards;
    }
    .change1{
        animation: myMou1 0.5s;
        animation-fill-mode: forwards;
    }
    @keyframes myMou {
        0% {
            position: absolute;
            bottom: 0;
        }
        100% {
            position: absolute;
            bottom: -160px;
        }
    }
    @keyframes myMou1 {
        0% { 
            position: absolute;
            bottom: -160px;
        }
        100% {
             position: absolute;
            bottom: 0;
        }
    }

    //触摸板
    .select{
        position: absolute;
        bottom: 0;
        height: 160px;
        width: 100%;
        z-index: 2;
        // border: 1px solid red;
    }

    .mr2{
        margin-right: 8px;
    }

    .switch{
        margin-left: 40px;
    }

    .box1{
        margin-left: 150px;
    }




    // .el-carousel__item h3 {
    //     color: #475669;
    //     font-size: 18px;
    //     opacity: 0.75;
    //     // line-height: 300px;
    //     margin: 0;
    // }
    
    // .el-carousel__item:nth-child(2n) {
    //     background-color: #99a9bf;
    // }
    
    // .el-carousel__item:nth-child(2n+1) {
    //     background-color: #d3dce6;
    // }

    ::v-deep .el-carousel{
        height: 100%;
    }  
    ::v-deep .el-carousel__container{
        height: 100%;
    }
    ::v-deep .el-carousel__arrow, .el-carousel__arrow--left{
        display: none;
    }
    ::v-deep .el-carousel__indicators{
        display: none;
    }

    .divBox{
        width: 50px;
        height: 50px;
        // margin-left: 10px;
        // border: 1px solid red;
    }
    .div{
        text-align: left;
        width: 250px;
        // border: 1px solid red;
    }
    .div11{
        text-align: left;
        width: 320px;
        // border: 1px solid red;
    }

    .windowBox{
        width: 223px;
        height: 302px;
        background: rgba($color: #173145, $alpha: 0.57);
    }

</style>